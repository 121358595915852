import React from 'react';
import { Logos } from '@/components/homepage/v4/logos';

const AnimatedLogos = ({
  logos,
  dark,
  borderless,
  inverted,
}: {
  logos: {
    asset: {
      url: string;
    };
  }[];
  dark?: boolean;
  borderless?: boolean;
  inverted?: boolean;
}) => {
  const border = {
    dark: borderless ? '' : 'border-y border-solid border-y-[#2A2A2A]',
    gray: borderless
      ? ''
      : 'border-t border-solid border-y-[rgb(0,0,0,0.05)] bg-[#EEF0FF] py-6',
  };
  const background = {
    dark: '',
    gray: 'bg-[#EEF0FF]',
  };
  const borderClasses = dark ? border.dark : border.gray;
  const backgroundClasses = dark ? background.dark : background.gray;
  const classes = `py-6 ${backgroundClasses} ${borderClasses}`;
  return (
    <div className={classes}>
      <Logos logos={logos} inverted={inverted} />
    </div>
  );
};

export default AnimatedLogos;
