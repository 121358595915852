import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';

import Container from '@/components/common/v5/container';
import NewsletterForm from '@/components/common/v5/newsletter-form';
import { trackEvent } from '@/utils/amplitude-tracking';

const FooterLink = ({ link, className }) => {
  if (link._type === 'internalLink') {
    return (
      <Link
        onClick={() => trackEvent(link)}
        to={`/${link.slug.current}`}
        className={className}
      >
        {link.label}
      </Link>
    );
  }
  if (link._type === 'externalLink') {
    return (
      <OutboundLink
        href={link.url}
        onClick={() => trackEvent(link)}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {link.label}
      </OutboundLink>
    );
  }
};

const Footer = () => {
  const query = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        headerLinks {
          ... on SanityDropDownItem {
            _key
            _type
            dropDownItems {
              ... on SanityExternalLink {
                _key
                _type
                url
                label
              }
              ... on SanityInternalLink {
                _key
                _type
                label
              }
            }
            label
          }
        }

        copyrightMessage
        socialLinks {
          name
          url
        }
        logoLight {
          asset {
            gatsbyImageData
          }
        }
        logoDark {
          asset {
            gatsbyImageData
          }
        }
        faqItems {
          question

          _rawAnswer(resolveReferences: { maxDepth: 10 })
        }
        footerLinksFive {
          label
          columnItems {
            ... on SanityExternalLink {
              _type
              url
              label
            }
            ... on SanityDownloadLink {
              _type
              url
              label
            }
            ... on SanityInternalLink {
              _type
              label
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);

  const { copyrightMessage, footerLinksFive, logoLight } =
    query.sanitySiteSettings;

  return (
    <footer>
      <Container className="relative z-10 overflow-hidden py-12 md:py-20">
        <div className="flex flex-wrap items-start justify-start gap-8 text-white/60 md:justify-between">
          <div className="flex w-auto flex-col items-start justify-start">
            <Link to="/" aria-label="Galileo Home">
              <img
                src={logoLight.asset.gatsbyImageData.images.fallback.src}
                height={40}
                width={123}
              />
            </Link>

            <div className="my-6 w-full">
              <p className="text-left text-base lg:mt-0">
                Subscribe to our newsletter
              </p>

              <div className="flex flex-row space-x-2">
                <NewsletterForm
                  cssClass="inline-newsletter-form"
                  portalId="23114811"
                  formId="646b31c4-03b9-48bc-bcc6-048771d5caf9"
                />
              </div>
            </div>
          </div>
          <div className="w-full md:w-auto">
            <div className="flex flex-row flex-wrap max-md:gap-8">
              {footerLinksFive.map(({ label, columnItems }, index) => (
                <div
                  className={`md:w-auto md:min-w-[9rem] ${
                    index == 0 ? 'w-full' : 'w-[40%]'
                  }`}
                  key={label + index}
                >
                  <p className="mb-4 text-xs font-light uppercase md:block">
                    {label}
                  </p>
                  <ul>
                    {columnItems.map((columnLink, idx) => (
                      <li className="mx-0 my-2" key={idx}>
                        <FooterLink
                          link={columnLink}
                          className="inline-block cursor-pointer py-1 text-sm text-white hover:text-primary-600"
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-8 flex flex-wrap justify-start gap-6 text-start text-sm text-grey-400 md:mt-36 md:justify-between lg:mt-60">
          <p>
            Contact us at{' '}
            <a
              href="mailto:info@rungalileo.io"
              className="bg-gradient-to-br from-reddish-magenta via-pb to-purply-blue bg-clip-text text-transparent antialiased"
            >
              info@rungalileo.io
            </a>
          </p>
          <p>{copyrightMessage}</p>
        </div>

        <div className="absolute -top-[35%] left-[70%] -z-10 h-[12.5rem] w-[49.5rem] bg-gradient-hero blur-[9rem] md:-top-[50%] md:left-[25%]" />
        <div className="absolute -bottom-[25%] left-[70%] -z-10 h-[12.5rem] w-[49.5rem] bg-gradient-hero blur-[9rem] md:-bottom-[40%] md:left-[25%]" />
      </Container>
    </footer>
  );
};

export default Footer;
