import React, { useState } from 'react';
import { PageProps } from 'gatsby';
import Layout from '@/components/common/v5/layout';
import Container from '@/components/common/container';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import HubspotGetStartedForm from '@/components/common/get-started-form';
import SEO from '@/components/seo';
import AnimatedLogos from '@/components/Logos';

const GetStarted = ({ data }) => {
  const { sanityHomePage, sanityGetStarted } = data;
  const {
    title,
    subtitle,
    productFeatures,
    hubspotFormId,
    formSuccessMessageTitle,
    formSuccessMessageCta,
  } = sanityGetStarted;
  const logos = data?.sanityHomePage?.homepageHeroSectionFive?.customersDark;

  return (
    <Layout>
      <div className="bg-white">
        <Container className="relative pb-44 pt-6 md:pb-52 md:pt-20">
          <FormBox
            title={title}
            subtitle={subtitle}
            productFeatureTitle={productFeatures.title}
            productFeatures={productFeatures.features}
            formId={hubspotFormId}
            formSuccessMessageTitle={formSuccessMessageTitle}
            ctaLinks={formSuccessMessageCta}
          />
        </Container>
        <AnimatedLogos logos={logos} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query GetStartedQuery {
    sanityGetStarted {
      title
      subtitle
      seo {
        metaTitle
        metaDescription
        image {
          asset {
            gatsbyImageData(width: 1280)
            url
            localFile(width: 1280) {
              publicURL
              childImageSharp {
                fixed(width: 1280) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }
      hubspotFormId
      formSuccessMessageTitle
      formSuccessMessageCta {
        ... on SanityExternalLink {
          _type
          label
          url
        }
      }
      productFeatures {
        title
        features {
          title
          subtitle
          image {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }

    sanityHomePage {
      homepageHeroSectionFive {
        customersDark {
          asset {
            url
          }
        }
      }
    }
  }
`;

export default GetStarted;

const FormBox = ({
  title,
  subtitle,
  productFeatures,
  productFeatureTitle,
  formId,
  formSuccessMessageTitle,
  ctaLinks,
}) => {
  return (
    <div className="flex flex-col-reverse items-center gap-24 xl:flex-row xl:justify-between">
      <div className="item-center flex flex-col xl:basis-1/2">
        <div className="rounded-lg bg-magnolia p-10">
          <h2 className="text-3xl md:text-4xl">{productFeatureTitle}</h2>

          <div className="flex flex-col gap-8">
            {productFeatures.map(({ title, subtitle, image }) => {
              return (
                <div key={title} className="mt-8 flex items-center gap-6">
                  <div className="shrink-0">
                    <GatsbyImage image={image.asset.gatsbyImageData} alt="" />
                  </div>
                  <div>
                    <h3 className="text-lg md:text-xl">{title}</h3>
                    <p className="font-outfit text-sm font-light text-gray-500 md:text-base">
                      {subtitle}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="flex-grow">
        <HubspotGetStartedForm
          cta={ctaLinks}
          formSuccessMessageTitle={formSuccessMessageTitle}
          title={title}
          subtitle={subtitle}
          formId={formId}
        />
      </div>
    </div>
  );
};

export const Head = ({ data }) => {
  const { seo } = data.sanityGetStarted;

  return (
    <SEO
      title={seo.metaTitle}
      description={seo.metaDescription}
      image={seo && seo.image ? seo.image.asset.localFile.publicURL : null}
      imageWidth={
        seo && seo.image
          ? seo.image.asset.localFile.childImageSharp.fixed.width
          : null
      }
      imageHeight={
        seo && seo.image
          ? seo.image.asset.localFile.childImageSharp.fixed.height
          : null
      }
    />
  );
};
